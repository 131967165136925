<template>
  <div class="controllerJianyan">
    <div class="controller-modules-header left">静脉输液</div>
    <div class="controller-checkbox">
      <a-checkbox-group v-model:value="checkValue">
        <a-row>
          <a-col :span="8" v-for="(item, index) in department" :key="index">
            <a-checkbox :value="item.code">{{ item.name }}</a-checkbox>
          </a-col>
        </a-row>
      </a-checkbox-group>
    </div>
    <button class="controller-btn no-margin-top" @click="submit('temperature')">
      确认
    </button>
    <div class="controller-modules-header left">检验记录</div>
    <div class="controller-modules-log">
      <block v-show="checkDataList.length != 0">
        <div v-for="(item, index) in checkDataList" :key="index">
          <div
            style="
              width: 100%;
              font-size: 12px;
              color: #152454;
              line-height: 22px;
              font-weight: 400;
            "
            v-if="checkDataListT.includes(index)"
          >
            {{ item }}
          </div>
        </div>
      </block>
    </div>
    <div class="controller-modules-log">
      <block v-show="actionLog.length != 0">
        <div class="item" v-for="(item, index) in actionLog" :key="index">
          【{{ formateSeconds(item.logTime) }}】{{ item.logContent }}
        </div>
      </block>
      <block v-show="actionLog.length == 0">
        <div class="item">
          <!-- 暂无操作记录。 -->
        </div>
      </block>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { formateSeconds } from "@/utils/tool";
export default {
  data() {
    return {
      checkValue: "",
      formateSeconds,
      actionLog: [],
      checkDataArray: [],
      checkDataList: [],
      checkDataListT: [],
      checkDataList1: [],
      checkDataList2: [],
      checkDataList3: [],
      checkDataList4: [],
    };
  },
  created() {
    this.getActionLog();
  },
  methods: {
    ...mapActions({
      submitStep: "exma/submitStep",
    }),
    async submit() {
      localStorage.setItem("Stops", JSON.stringify("Yes"));
      let arrs = [];
      this.checkDataList = [];
      this.checkValue.forEach((items) => {
        this.department.find((item, index) => {
          if (item.code == items) {
            arrs.push(index);
          }
        });
      });
      if (this.checkDataArray) {
        arrs.forEach((item) => {
          this.checkDataList.push(this.checkDataArray[item]);
        });
        let indexss = JSON.parse(localStorage.getItem("Times"));
        let checkDataListClone = JSON.parse(localStorage.getItem("checkDataArray"))[
          indexss
        ];
        let checkDataListClones = [];
        let checks = [];
        for (let key in checkDataListClone) {
          if (key.split("_")[1] == "ph") {
            checks[0] = "ABG:ph:" + checkDataListClone[key];
          } else if (key.split("_")[1] == "glucose") {
            console.log();
            checks[7] = "Blood glucose:" + checkDataListClone[key];
          } else {
            let firstKey = key.split("_")[1] == undefined ? key : key.split("_")[1];
            const characters = [...firstKey];
            characters[0] = characters[0].toUpperCase();
            let keyss = characters.join("");
            // checks.push(keyss+':'+item[key])
            if (keyss == "Pco2") {
              checks[1] = keyss + ":" + checkDataListClone[key];
            }
            if (keyss == "Po2") {
              checks[2] = keyss + ":" + checkDataListClone[key];
            }
            if (keyss == "Hco3") {
              checks[3] = "HCO3" + ":" + checkDataListClone[key];
            }
            if (keyss == "Hematocrit") {
              checks[4] = keyss + ":" + checkDataListClone[key];
            }
            if (keyss == "Na") {
              checks[5] = keyss + ":" + checkDataListClone[key];
            }
            if (keyss == "K") {
              checks[6] = keyss + ":" + checkDataListClone[key];
            }
          }
        }
        this.checkDataList4 = checks.splice(0, 4);
        this.checkDataList1 = checks.splice(0, 1);
        this.checkDataList2 = checks.splice(0, 2);
        this.checkDataList3 = checks.splice(0, 1);

        checkDataListClones.push(this.checkDataList4.join(","));
        checkDataListClones.push(this.checkDataList1.join(","));
        checkDataListClones.push(this.checkDataList2.join(","));
        checkDataListClones.push(this.checkDataList3.join(","));
        this.checkDataListT = checkDataListClones;
        this.checkValue.forEach((item, index) => {
          if (item == "arterial_blood_gas") {
            this.checkDataListT.push(0);
          }
          if (item == "hematocrit") {
            this.checkDataListT.push(1);
          }
          if (item == "electrolytes") {
            this.checkDataListT.push(2);
          }
          if (item == "blood_glucose") {
            this.checkDataListT.push(3);
          }
        });
        this.checkDataList = checkDataListClones;
      }
      console.log(this.checkValue);
      if (!this.checkValue) return;
      let d = {
        memberCaseId: this.memberCaseId,
        component: "labs",
        actionTime: this.getHandleTimeAt,
        otherData: {
          labs: this.checkValue,
        },
      };
      // this.submitStep(d).then(res=>{
      this.getActionLog();
      // });
    },

    async getActionLog() {
      try {
        let res = await this.$http.post(this.$interface.examActionLog, {
          componentCategory: "labs",
          memberCaseId: this.memberCaseId,
        });
        this.actionLog = res.data.data || [];
      } catch (err) {}
    },
  },
  computed: {
    ...mapGetters(["getHandleTimeAt", "memberCaseId", "department"]),
  },
};
</script>
<style lang="scss">
@import "../index.scss";
</style>
